@import "typo";

:root {
  font-family: "Suisseintl","Helvetica","Arial", sans-serif;
  font-size: 16px;
  line-height: 1.1;

  @media screen and (max-width: 679.99px) {
    font-size: 14px;
  }

  color: #212322;

  --black: #212322;
  --white: #fafafa;
  --grayLight: #bdbdbd;
  --gray: #707070;
  --success: #00996b;
  --info: #006fea;
  --warning: #d9a900;
  --danger: #e34f00;

  --color-gray: #aaaaaa;
  --info-gradient: linear-gradient(var(--info) 60%, #006fea 100%);
  --success-gradient: linear-gradient(var(--success) 60%, #00855d 100%);
  --warning-gradient: linear-gradient(var(--warning) 60%, #ca9d00 100%);
  --danger-gradient: linear-gradient(var(--danger) 60%, #ca4600 100%);
}

body {
  padding: 0;
  margin: 0;
}

.MuiBox-root {
  border-radius: 5px;
}

.MuiTypography-overline {
  color: var(--color-gray);
}

h1,
h2,
h3 {
  font-family: "Suisseintl";
  font-weight: 700;
}

h1 {
  font-size: 4rem;
  margin: 1rem 0;
}

h2 {
  font-size: 2rem;
}

p {
  line-height: 1.5;
}

a {
  color: #a5a6a6;

  &:hover {
    color: #7c7c7c;
  }

  &:active {
    color: #7c7c7c;
  }
}

hr {
  border: 1px solid #e9e9e9;
  margin: 0 -2rem;
}

ul {
  padding-left: 0;
  li {
    list-style: none;
    line-height: 2rem;
  }
}
.google {
  background-color: #e94235 !important;
  color: var(--white) !important;
}

.facebook {
  background-color: #4267b2 !important;
  color: var(--white) !important;
}

@media screen and (max-width: 679.99px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}
