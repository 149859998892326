@font-face {
    font-family: "Suisseintl";
    src: url("./fonts/SuisseIntl-Regular-WebM.woff")
            format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Suisseintl";
    src: url("./fonts/SuisseIntl-Bold-WebM.woff")
            format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
